<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      v-if="!loading && !form.id"
      class="alert alert-danger"
    >
      <div class="alert-body">
        <p>You are not authorized to access this form. If you think you should be able to access it, contact administration.</p>
      </div>
    </div>
    <div
      v-if="!loading && form.id"
      class="content-wrapper"
    >
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ form.title }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <p v-html="form.description" />
      <p>Date limit: <strong>{{ form.end_date }}</strong></p>
      <div
        class="content-body content-body--senior-call"
      >
        <div class="row">
          <div class="col-12">

            <!-- title section -->
            <div class="card card__request__form">
              <div class="card-content">
                <div class="card-body">
                  <FormSection
                    v-for="block in form.blocks"
                    :key="block.id"
                    :block="block"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="page-actions">
          <button
            id="type-success"
            class="btn btn-success"
            :class="{'d-none': submit}"
            @click="sendForm"
          >
            <i data-feather="save" /> Send
          </button>
          <button
            class="btn btn-outline-success"
            type="button"
            disabled=""
            :class="{'d-none': !submit}"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Sending...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import FormSection from './partials/FormSection.vue'

export default {
  components: {
    FormSection,
  },
  data() {
    return {
      slug: this.$route.params.form,
      loading: true,
      submit: false,
    }
  },
  computed: {
    ...mapGetters({
      form: 'requestForms/item',
    }),
    hasPendingRequired() {
      return this.form.blocks.filter(e => e.required && (e.type !== 'date_hour' ? (!e.answer || e.answer.length === 0) : this.isValidDate(e)))
    },
  },
  async mounted() {
    await this.$store.dispatch('requestForms/fetchSlug', this.slug)
    this.setLoading(false)

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    isValidDate(date) {
      const required = []
      if (date.date && !date.answer_date) {
        required.push('date')
      }

      if (date.hour && !date.answer_hour) {
        required.push('hour')
      }

      return required.length > 0
    },
    setLoading(loading) {
      this.loading = loading
    },
    async sendForm() {
      this.submit = true

      if (this.hasPendingRequired.length > 0) {
        Vue.swal('Required sections pending to be answered', this.hasPendingRequired.map(e => e.title).join('<br>'), 'warning')
      } else {
        const data = { ...this.form }
        await this.$store.dispatch('requestForms/answerForm', this.form)
        data.blocks.forEach(e => {
          if (e.type === 'upload' && e.answer) {
            Vue.prototype.$saveFiles([e.answer])
          }
        })

        this.$router.push({ name: 'request-forms.ok' })
      }

      this.submit = false
    },
  },
}
</script>
